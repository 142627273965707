import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Reviews } from '../../components';

import css from './ListingPage.module.css';

const SectionReviews = props => {
    const { reviews, fetchReviewsError } = props;

    const reviewsError = (
        <h2 className={css.errorText}>
            <FormattedMessage id="ListingPage.reviewsError" />
        </h2>
    );

    return (
        <div className={css.sectionReviews}>
            <h2 className={css.reviewsHeading}>
                <svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.3125 23.5625H23.5625V25.375H16.3125V23.5625Z"
                        fill="#059669"
                    />
                    <path
                        d="M16.3125 19.9375H27.1875V21.75H16.3125V19.9375Z"
                        fill="#059669"
                    />
                    <path
                        d="M16.3125 16.3125H27.1875V18.125H16.3125V16.3125Z"
                        fill="#059669"
                    />
                    <path
                        d="M18.6227 10.1654L14.5002 1.8125L10.3776 10.1654L1.16016 11.5048L7.83016 18.0072L6.25509 27.1875L12.6877 23.8063V21.7582L8.663 23.8742L9.61637 18.3135L9.77769 17.3737L9.09528 16.7094L5.05522 12.77L10.6386 11.9589L11.5811 11.822L12.0034 10.9674L14.5002 5.90784L16.9969 10.9674L17.4192 11.822L18.3617 11.9589L25.1169 12.9422L25.3752 11.1469L18.6227 10.1654Z"
                        fill="#059669"
                    />
                </svg>
                &nbsp;
                <FormattedMessage
                    id="ListingPage.reviewsHeading"
                    values={{ count: reviews.length }}
                />
            </h2>
            {fetchReviewsError ? reviewsError : null}
            <Reviews reviews={reviews} />
        </div>
    );
};

export default SectionReviews;
