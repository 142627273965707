import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ResponsiveImageLayout from '../../components/ResponsiveImage/ResponsiveImageLayout';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';

const SectionImages = props => {
    const {
        title,
        listing,
        isOwnListing,
        editParams,
        handleViewPhotosClick,
        imageCarouselOpen,
        onImageCarouselClose,
        onManageDisableScrolling,
    } = props;

    const [selectedImage, setSelectedImage] = useState(null);

    const hasImages = listing.images && listing.images.length > 0;
    const allImages = listing && listing.images;

    const firstImage = hasImages ? listing.images[0] : null;
    const secondImage = hasImages ? listing.images[1] : null;
    const thirdImage = hasImages ? listing.images[2] : null;

    // listing.images -> for images resource

    // Action bar is wrapped with a div that prevents the click events
    // to the parent that would otherwise open the image carousel
    const actionBar = listing.id ? (
        <div onClick={e => e.stopPropagation()}>
            <ActionBarMaybe
                isOwnListing={isOwnListing}
                listing={listing}
                editParams={editParams}
            />
        </div>
    ) : null;

    const viewPhotosButton = hasImages ? (
        <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
            <FormattedMessage
                id="ListingPage.viewImagesButton"
                values={{ count: listing.images.length }}
            />
        </button>
    ) : null;

    const imagesLength = listing.images.length;

    const imageLayout = imagesLength => {
        if (imagesLength === 1) {
            return (
                <ResponsiveImageLayout
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={firstImage}
                    variants={[
                        'landscape-crop',
                        'landscape-crop2x',
                        'landscape-crop4x',
                        'landscape-crop6x',
                    ]}
                    setSelectedImage={setSelectedImage}
                />
            );
        } else if (imagesLength === 2) {
            return (
                <div className={css.imageContainer}>
                    <div className={css.imageColumnFirst}>
                        <ResponsiveImageLayout
                            rootClassName={css.imageFirst}
                            alt={title}
                            image={firstImage}
                            variants={[
                                'landscape-crop',
                                'landscape-crop2x',
                                'landscape-crop4x',
                                'landscape-crop6x',
                            ]}
                            setSelectedImage={setSelectedImage}
                        />
                    </div>
                    <div className={css.imageColumnSecond}>
                        <ResponsiveImageLayout
                            rootClassName={css.imageSecondAlt}
                            alt={title}
                            image={secondImage}
                            variants={[
                                'landscape-crop',
                                'landscape-crop2x',
                                'landscape-crop4x',
                                'landscape-crop6x',
                            ]}
                            setSelectedImage={setSelectedImage}
                        />
                    </div>
                </div>
            );
        } else if (imagesLength > 2) {
            return (
                <div className={css.imageContainer}>
                    <div className={css.imageColumnFirst}>
                        <ResponsiveImageLayout
                            rootClassName={css.imageFirst}
                            alt={title}
                            image={firstImage}
                            variants={[
                                'landscape-crop',
                                'landscape-crop2x',
                                'landscape-crop4x',
                                'landscape-crop6x',
                            ]}
                            setSelectedImage={setSelectedImage}
                        />
                    </div>
                    <div className={css.imageColumnSecond}>
                        <ResponsiveImageLayout
                            rootClassName={css.imageSecond}
                            alt={title}
                            image={secondImage}
                            variants={[
                                'landscape-crop',
                                'landscape-crop2x',
                                'landscape-crop4x',
                                'landscape-crop6x',
                            ]}
                            setSelectedImage={setSelectedImage}
                        />
                        <ResponsiveImageLayout
                            rootClassName={css.imageThird}
                            alt={title}
                            image={thirdImage}
                            variants={[
                                'landscape-crop',
                                'landscape-crop2x',
                                'landscape-crop4x',
                                'landscape-crop6x',
                            ]}
                            setSelectedImage={setSelectedImage}
                        />
                    </div>
                </div>
            );
        }
    };

    return (
        <center>
            {actionBar}
            <div className={css.sectionImages}>
                <div className={css.threeToTwoWrapper}>
                    <div
                        className={css.aspectWrapper}
                        onClick={handleViewPhotosClick}>
                        {imageLayout(imagesLength)}

                        {viewPhotosButton}
                    </div>
                </div>

                <Modal
                    id="ListingPage.imageCarousel"
                    scrollLayerClassName={css.carouselModalScrollLayer}
                    containerClassName={css.carouselModalContainer}
                    lightCloseButton
                    isOpen={imageCarouselOpen}
                    onClose={onImageCarouselClose}
                    usePortal
                    onManageDisableScrolling={onManageDisableScrolling}>
                    <ImageCarousel
                        images={listing.images}
                        selectedImage={selectedImage}
                    />
                </Modal>
            </div>
        </center>
    );
};

export default SectionImages;
