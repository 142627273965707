import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
    const { options, publicData } = props;
    if (!publicData) {
        return null;
    }

    const selectedOptions =
        publicData && publicData.amenities ? publicData.amenities : [];

    return (
        <div className={css.sectionFeatures}>
            <h2 className={css.featuresTitle}>
                <svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.639 16.6425L12.2881 17.9934L13.6361 20.6895L14.9625 19.2895L13.639 16.6425Z"
                        fill="#059669"
                    />
                    <path
                        d="M14.4985 22.4145L15.7616 24.9407L17.088 23.5406L15.8249 21.0145L14.4985 22.4145Z"
                        fill="#059669"
                    />
                    <path
                        d="M24.8611 11.4765L23.5513 12.8592L26.0674 14.0626L27.3773 12.6799L24.8611 11.4765Z"
                        fill="#059669"
                    />
                    <path
                        d="M19.4053 10.8764L21.8329 12.0374L23.1429 10.6548L20.7644 9.51722L19.4053 10.8764Z"
                        fill="#059669"
                    />
                    <path
                        d="M17.4331 19.3171L19.0487 21.4712L20.3132 20.1364L18.6976 17.9823L17.4331 19.3171Z"
                        fill="#059669"
                    />
                    <path
                        d="M17.5942 16.5114L15.9553 14.3261L14.6606 15.6207L16.3297 17.8462L17.5942 16.5114Z"
                        fill="#059669"
                    />
                    <path
                        d="M17.0342 13.2473L19.1119 14.9096L20.3628 13.5891L18.3237 11.9578L17.0342 13.2473Z"
                        fill="#059669"
                    />
                    <path
                        d="M20.5322 16.0458L22.6121 17.7097L23.863 16.3892L21.7831 14.7254L20.5322 16.0458Z"
                        fill="#059669"
                    />
                    <path
                        d="M19.7499 5.70297L18.2636 7.18917L16.8636 5.78912C16.2765 5.20211 15.5797 4.73646 14.8127 4.41877C14.0457 4.10108 13.2237 3.93757 12.3935 3.93757C11.5633 3.93757 10.7413 4.10108 9.97433 4.41877C9.20736 4.73646 8.51047 5.20211 7.92346 5.78912C7.90409 5.80844 7.88534 5.82815 7.86631 5.84769C7.20491 5.38175 6.42824 5.10643 5.62105 5.05178C4.81386 4.99714 4.00716 5.16527 3.289 5.53782C2.57085 5.91037 1.96882 6.47303 1.54863 7.1644C1.12844 7.85576 0.90623 8.64926 0.90625 9.4583V23.1094H2.71875V9.4583C2.71874 8.98365 2.84846 8.51802 3.09389 8.11175C3.33932 7.70548 3.69112 7.37401 4.11128 7.15318C4.53143 6.93235 5.00395 6.83056 5.47776 6.8588C5.95157 6.88705 6.40865 7.04426 6.79959 7.31344C6.17254 8.50805 5.94339 9.87171 6.14555 11.2057C6.34771 12.5396 6.97061 13.7742 7.92346 14.7293L9.32344 16.1293L7.9686 17.4842L9.10141 18.617L20.8827 6.83579L19.7499 5.70297ZM10.4789 14.7215L9.20506 13.4477C8.36764 12.6004 7.89956 11.4561 7.90305 10.2648C7.90654 9.07351 8.38132 7.93198 9.22369 7.08958C10.0661 6.24718 11.2076 5.77236 12.3989 5.76883C13.5902 5.7653 14.7345 6.23334 15.5818 7.07073L16.8556 8.34458L10.4789 14.7215Z"
                        fill="#059669"
                    />
                </svg>

                <FormattedMessage id="ListingPage.featuresTitle" />
            </h2>
            <PropertyGroup
                id="ListingPage.amenities"
                options={options}
                selectedOptions={selectedOptions}
                twoColumns={true}
            />
        </div>
    );
};

export default SectionFeaturesMaybe;
