import React from 'react';
import PropTypes, { number } from 'prop-types';
import classNames from 'classnames';
import { IconReviewStar } from '..';
import IconReviewBall from '../IconReviewStar/IconReviewBall';
import { REVIEW_RATINGS } from '../../util/types';
import { Math } from 'core-js';
import css from '../IconReviewStar/IconReviewStar.module.css';

const CourtRating = props => {
    const { className, rootClassName, reviewStarClassName, avgRating } = props;
    const classes = classNames(rootClassName, className);
    const stars = REVIEW_RATINGS;

    function rating(avgRating) {
        let array = [];
        for (let i = 0; i < avgRating; i++) {
            array.push(
                <span key={i}>
                    <IconReviewBall key={i} size={1} />
                </span>
            );
        }
        return array;
    }

    return (
        <span className={classes}>
            <span className={css.iconContainer}>{rating(avgRating)}</span>
        </span>
    );
};

CourtRating.defaultProps = {
    rootClassName: null,
    className: null,
    reviewStarClassName: null,
    avgRating: null,
};

const { string, oneOf } = PropTypes;

CourtRating.propTypes = {
    rating: oneOf(REVIEW_RATINGS),
    reviewStartClassName: string,
    rootClassName: string,
    className: string,
    avgRating: number,
};

export default CourtRating;
