import React from 'react';
import { AvatarLarge, AvatarMedium } from '../../components';

import css from './ListingPage.module.css';

const SectionAvatar = props => {
    const { user } = props;
    const { profile } = user.attributes;

    return (
        <center>
            <div className={css.sectionAvatar}>
                <AvatarLarge
                    user={user}
                    className={css.avatarDesktop}
                    initialsClassName={css.initialsDesktop}
                    // disableProfileLink
                />

                {/* <AvatarMedium user={user} className={css.avatarMobile} disableProfileLink /> */}

                <div className={css.authorName}>
                    Court by&nbsp;
                    {profile && <b>{profile.displayName}</b>}
                </div>
            </div>
        </center>
    );
};

export default SectionAvatar;
