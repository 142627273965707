import React, { Component } from 'react';
import {
    array,
    arrayOf,
    bool,
    func,
    object,
    shape,
    string,
    oneOf,
} from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import {
    LISTING_STATE_PENDING_APPROVAL,
    LISTING_STATE_CLOSED,
    propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    LISTING_PAGE_PARAM_TYPE_DRAFT,
    LISTING_PAGE_PARAM_TYPE_EDIT,
    createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import {
    createResourceLocatorString,
    findRouteByRouteName,
} from '../../util/routes';
import {
    ensureListing,
    ensureOwnListing,
    ensureUser,
    userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
    manageDisableScrolling,
    isScrollingDisabled,
} from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { searchNearByMapListings } from './ListingPage.duck';
import {
    Page,
    Modal,
    NamedLink,
    NamedRedirect,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    BookingPanel,
    Button,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';

import {
    sendEnquiry,
    setInitialValues,
    fetchTimeSlots,
    fetchTransactionLineItems,
    storeBookingDatesArray,
    removeBookingDate,
} from './ListingPage.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionMapMaybe from './SectionMapMaybe';
import SectionPrecaution from './SectionPrecaution';
import SectionCourtType from './SectionCourtType';
import SectionCourtSurface from './SectionCourtSurface';
import SectionInfo from './SectionInfo';
import SectionNearbyListings from './SectionNearbyListings';
import css from './ListingPage.module.css';
import { valuesIn } from 'lodash';
import { Math } from 'core-js';
import SectionPolicy from './SectionPolicy';
import SectionVideo from './SectionVideo';
import successImage from './successImage.png';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, LatLng, LatLngBounds } = sdkTypes;

const priceData = (price, intl) => {
    if (price && price.currency === config.currency) {
        const formattedPrice = formatMoney(intl, price);
        return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
        return {
            formattedPrice: `(${price.currency})`,
            priceTitle: `Unsupported currency (${price.currency})`,
        };
    }
    return {};
};

export class ListingPageComponent extends Component {
    constructor(props) {
        super(props);
        const { enquiryModalOpenForListingId, params } = props;
        this.state = {
            pageClassNames: [],
            imageCarouselOpen: false,
            enquiryModalOpen: enquiryModalOpenForListingId === params.id,
            fillInInfoModalOpen: false,
            thanksModalOpen: true,
            bookingDatesArray: [],
            bookingsDatesArray: [],
            selectedImage: null,
            canSearchForNearByListings: true,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInstantSubmit = this.handleInstantSubmit.bind(this);
        this.onContactUser = this.onContactUser.bind(this);
        this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    }

    // ========== SUBMIT BOOKING ========== //
    handleSubmit(values) {

        const {
            history,
            getListing,
            params,
            callSetInitialValues,
            onInitializeCardPaymentData,
        } = this.props;

        const listingId = new UUID(params.id);
        const listing = getListing(listingId);

        /**
         * booking form gets cleared up everytime the fields are filled in from the BookingTimeForm.js so
         * this line will no longer be used because we do not have any
         * dates value get passed through the form
         */

        const newBookingsDatesArray = this.state.bookingsDatesArray.map(b => {
            const bookingStart = timestampToDate(b.bookingStartTime);
            const bookingEnd = timestampToDate(b.bookingEndTime);
            return { bookingStart, bookingEnd };
        });
        const bookingsDataArray = newBookingsDatesArray.map(d => {
            return {
                quantity: calculateQuantityFromHours(
                    d.bookingStart,
                    d.bookingEnd
                ),
            };
        });

        const initialValues = {
            listing,
            bookingsDataArray,
            bookingsDatesArray: newBookingsDatesArray,
            bookingDatesArray: this.state.bookingDatesArray,
            confirmPaymentError: null,
        };

        const saveToSessionStorage = !this.props.currentUser;

        const routes = routeConfiguration();
        // Customize checkout page state with current listing and selected bookingDates
        const { setInitialValues } = findRouteByRouteName(
            'CheckoutPage',
            routes
        );

        callSetInitialValues(
            setInitialValues,
            initialValues,
            saveToSessionStorage
        );

        // Clear previous Stripe errors from store if there is any
        onInitializeCardPaymentData();

        // Redirect to CheckoutPage with listing data (id)/slug
        history.push(
            createResourceLocatorString(
                'CheckoutPage',
                routes,
                {
                    id: listing.id.uuid,
                    slug: createSlug(listing.attributes.title),
                },
                {}
            )
        );
    }

    // ========== SUBMIT INSTANT BOOKING ========== //
    handleInstantSubmit(values) {
        const {
            history,
            getListing,
            params,
            callSetInitialValues,
            onInitializeCardPaymentData,
        } = this.props;

        const listingId = new UUID(params.id);
        const listing = getListing(listingId);

        /**
         * booking form gets cleared up everytime the fields are filled in from the BookingTimeForm.js so
         * this line will no longer be used because we do not have any
         * dates value get passed through the form
         */

        const newBookingsDatesArray = this.state.bookingsDatesArray.map(b => {
            const bookingStart = timestampToDate(b.bookingStartTime);
            const bookingEnd = timestampToDate(b.bookingEndTime);
            return { bookingStart, bookingEnd };
        });
        const bookingsDataArray = newBookingsDatesArray.map(d => {
            return {
                quantity: calculateQuantityFromHours(
                    d.bookingStart,
                    d.bookingEnd
                ),
            };
        });

        const initialValues = {
            listing,
            bookingsDataArray,
            bookingsDatesArray: newBookingsDatesArray,
            bookingDatesArray: this.state.bookingDatesArray,
            confirmPaymentError: null,
            bookingMethod: 'instant',
        };

        const saveToSessionStorage = !this.props.currentUser;

        const routes = routeConfiguration();
        // Customize checkout page state with current listing and selected bookingDates
        const { setInitialValues } = findRouteByRouteName(
            'CheckoutPage',
            routes
        );

        callSetInitialValues(
            setInitialValues,
            initialValues,
            saveToSessionStorage
        );

        // Clear previous Stripe errors from store if there is any
        onInitializeCardPaymentData();

        // Redirect to CheckoutPage with listing data (id)/slug
        history.push(
            createResourceLocatorString(
                'CheckoutPage',
                routes,
                {
                    id: listing.id.uuid,
                    slug: createSlug(listing.attributes.title),
                },
                {}
            )
        );
    }

    onContactUser() {
        const {
            currentUser,
            history,
            callSetInitialValues,
            params,
            location,
        } = this.props;

        if (!currentUser) {
            const state = {
                from: `${location.pathname}${location.search}${location.hash}`,
            };

            // We need to log in before showing the modal, but first we need to ensure
            // that modal does open when user is redirected back to this listingpage
            callSetInitialValues(setInitialValues, {
                enquiryModalOpenForListingId: params.id,
            });

            // signup and return back to listingPage.
            history.push(
                createResourceLocatorString(
                    'SignupPage',
                    routeConfiguration(),
                    {},
                    {}
                ),
                state
            );
        } else {
            this.setState({ enquiryModalOpen: true });
        }
    }

    onSubmitEnquiry(values) {
        const { history, params, onSendEnquiry } = this.props;
        const routes = routeConfiguration();
        const listingId = new UUID(params.id);
        const { message } = values;

        onSendEnquiry(listingId, message.trim())
            .then(txId => {
                this.setState({ enquiryModalOpen: false });

                // Redirect to OrderDetailsPage
                history.push(
                    createResourceLocatorString(
                        'OrderDetailsPage',
                        routes,
                        { id: txId.uuid },
                        {}
                    )
                );
            })
            .catch(() => {
                // Ignore, error handling in duck file
            });
    }

    // onStoreBookingDates(values) {
    //   var bookingArray = [];

    //   if (this.state.bookingDatesArray.length > 0) {
    //     bookingArray = [...this.state.bookingDatesArray, values];
    //   } else {
    //     bookingArray = [values];
    //   }

    //   var bookingArray = bookingArray.filter(value => Object.keys(value).length !== 0);

    //   // React state
    //   this.setState({ bookingDatesArray: bookingArray });
    // }
    onStoreBookingDates(values) {
        var bookingArray = [];

        if (this.state.bookingDatesArray.length > 0) {
            bookingArray = [...this.state.bookingDatesArray, values];
        } else {
            bookingArray = [values];
        }

        var bookingArray = bookingArray.filter(
            value => Object.keys(value).length !== 0
        );
        this.setState({ bookingDatesArray: bookingArray });
    }

    render() {
        const {
            unitType,
            isAuthenticated,
            currentUser,
            getListing,
            getOwnListing,
            intl,
            onManageDisableScrolling,
            onFetchTimeSlots,
            params: rawParams,
            location,
            scrollingDisabled,
            showListingError,
            reviews,
            fetchReviewsError,
            sendEnquiryInProgress,
            sendEnquiryError,
            monthlyTimeSlots,
            filterConfig,
            onFetchTransactionLineItems,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            bookingDatesArray,
            onStoreBookingDates,
            onRemoveBookingDate,
            onUpdateProfile,
            onSearchNearByMapListings,
            nearbyListings,
            history
        } = this.props;

        const listingId = new UUID(rawParams.id);
        const isPendingApprovalVariant =
            rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
        const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
        const currentListing =
            isPendingApprovalVariant || isDraftVariant
                ? ensureOwnListing(getOwnListing(listingId))
                : ensureListing(getListing(listingId));
        const listingSlug =
            rawParams.slug || createSlug(currentListing.attributes.title || '');
        const params = { slug: listingSlug, ...rawParams };

        const listingType = isDraftVariant
            ? LISTING_PAGE_PARAM_TYPE_DRAFT
            : LISTING_PAGE_PARAM_TYPE_EDIT;
        const listingTab = isDraftVariant ? 'publish' : 'description';

        const isApproved =
            currentListing.id &&
            currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

        // const currentListingPublicData = currentListing.id && currentListing.attributes.publicData;

        const pendingIsApproved = isPendingApprovalVariant && isApproved;

        const setSelectedImage = values => {
            this.setState({ selectedImage: values });
        };

        const addNewBooking = values => {
            this.setState({
                bookingsDatesArray: [...this.state.bookingsDatesArray, values],
            });
        };

        const deleteBooking = indexKey => {
            let _bookingsDatesArray = this.state.bookingsDatesArray;
            _bookingsDatesArray = _bookingsDatesArray.filter(
                (data, i) => i != indexKey
            );
            this.setState({ bookingsDatesArray: _bookingsDatesArray });
        };

        // If a /pending-approval URL is shared, the UI requires
        // authentication and attempts to fetch the listing from own
        // listings. This will fail with 403 Forbidden if the author is
        // another user. We use this information to try to fetch the
        // public listing.
        const pendingOtherUsersListing =
            (isPendingApprovalVariant || isDraftVariant) &&
            showListingError &&
            showListingError.status === 403;
        const shouldShowPublicListingPage =
            pendingIsApproved || pendingOtherUsersListing;

        if (shouldShowPublicListingPage) {
            return (
                <NamedRedirect
                    name="ListingPage"
                    params={params}
                    search={location.search}
                />
            );
        }

        const {
            description = '',
            geolocation = null,
            price = null,
            title = '',
            publicData,
        } = currentListing.attributes;

        const richTitle = (
            <span>
                {richText(title, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
                    longWordClass: css.longWord,
                })}
            </span>
        );

        const bookingTitle = (
            <FormattedMessage
                id="ListingPage.bookingTitle"
                values={{ title: richTitle }}
            />
        );

        const topbar = <TopbarContainer />;

        if (showListingError && showListingError.status === 404) {
            // 404 listing not found

            return <NotFoundPage />;
        } else if (showListingError) {
            // Other error in fetching listing

            const errorTitle = intl.formatMessage({
                id: 'ListingPage.errorLoadingListingTitle',
            });

            return (
                <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
                    <LayoutSingleColumn className={css.pageRoot}>
                        <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
                        <LayoutWrapperMain>
                            <p className={css.errorText}>
                                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
                            </p>
                        </LayoutWrapperMain>
                        <LayoutWrapperFooter>
                            <Footer />
                        </LayoutWrapperFooter>
                    </LayoutSingleColumn>
                </Page>
            );
        } else if (!currentListing.id) {
            // Still loading the listing

            const loadingTitle = intl.formatMessage({
                id: 'ListingPage.loadingListingTitle',
            });

            return (
                <Page
                    title={loadingTitle}
                    scrollingDisabled={scrollingDisabled}>
                    <LayoutSingleColumn className={css.pageRoot}>
                        <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
                        <LayoutWrapperMain>
                            <p className={css.loadingText}>
                                <FormattedMessage id="ListingPage.loadingListingMessage" />
                            </p>
                        </LayoutWrapperMain>
                        <LayoutWrapperFooter>
                            <Footer />
                        </LayoutWrapperFooter>
                    </LayoutSingleColumn>
                </Page>
            );
        }

        const handleViewPhotosClick = e => {
            // Stop event from bubbling up to prevent image click handler
            // trying to open the carousel as well.
            e.stopPropagation();
            this.setState({
                imageCarouselOpen: true,
            });
        };

        const authorAvailable = currentListing && currentListing.author;
        const userAndListingAuthorAvailable = !!(
            currentUser && authorAvailable
        );
        const isOwnListing =
            userAndListingAuthorAvailable &&
            currentListing.author.id.uuid === currentUser.id.uuid;
        const showContactUser =
            authorAvailable && (!currentUser || (currentUser && !isOwnListing));

        const currentAuthor = authorAvailable ? currentListing.author : null;
        const ensuredAuthor = ensureUser(currentAuthor);

        // When user is banned or deleted the listing is also deleted.
        // Because listing can be never showed with banned or deleted user we don't have to provide
        // banned or deleted display names for the function
        const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

        const { formattedPrice, priceTitle } = priceData(price, intl);

        const handleBookingSubmit = values => {
            const isCurrentlyClosed =
                currentListing.attributes.state === LISTING_STATE_CLOSED;
            if (
                currentUser &&
                currentUser.profileImage &&
                currentUser.attributes.profile.firstName &&
                currentUser.attributes.profile.lastName
            ) {
                if (isOwnListing || isCurrentlyClosed) {
                    window.scrollTo(0, 0);
                } else {
                    this.handleSubmit(values);
                }
            } else {
                if (!currentUser) {
                    this.handleSubmit(values);
                } else {
                    this.setState({ fillInInfoModalOpen: true });
                }
            }
        };

        const handleInstantBookingSubmit = values => {
            const isCurrentlyClosed =
                currentListing.attributes.state === LISTING_STATE_CLOSED;
            if (
                currentUser &&
                currentUser.profileImage &&
                currentUser.attributes.profile.firstName &&
                currentUser.attributes.profile.lastName
            ) {
                if (isOwnListing || isCurrentlyClosed) {
                    window.scrollTo(0, 0);
                } else {
                    this.handleInstantSubmit(values);
                }
            } else {
                if (!currentUser) {
                    this.handleInstantSubmit(values);
                } else {
                    this.setState({ fillInInfoModalOpen: true });
                }
            }
        };

        const storeBookingDatesArray = values => {
            this.onStoreBookingDates(values);
        };

        const addToFav = id => {
            if (!isAuthenticated) {
                const state = {
                    from: `${this.props.location.pathname}${this.props.location.search}${this.props.location.hash}`,
                };
                // signup and return back to listingPage.
                history.push(
                    createResourceLocatorString(
                        'LoginPage',
                        routeConfiguration(),
                        {},
                        {}
                    ),
                    state
                );
            }

            const { publicData } =
                (currentUser &&
                    currentUser.attributes &&
                    currentUser.attributes.profile) ||
                {};
            const { favCourts } = publicData || {};
            let _favCourts = [];

            if (favCourts && favCourts.length) {
                // already have favourite courts
                const isFavCourtExist = favCourts.find(
                    courtId => courtId === id
                );
                _favCourts = isFavCourtExist
                    ? favCourts.filter(courtId => courtId !== id)
                    : [...favCourts, id];
            } else {
                _favCourts = [id];
            }

            const updatedValues = {
                publicData: { favCourts: _favCourts },
            };

            onUpdateProfile(updatedValues);
        };

        const listingImages = (listing, variantName) =>
            (listing.images || [])
                .map(image => {
                    const variants = image.attributes.variants;
                    const variant = variants ? variants[variantName] : null;

                    // deprecated
                    // for backwards combatility only
                    const sizes = image.attributes.sizes;
                    const size = sizes
                        ? sizes.find(i => i.name === variantName)
                        : null;

                    return variant || size;
                })
                .filter(variant => variant != null);

        const facebookImages = listingImages(currentListing, 'facebook');
        const twitterImages = listingImages(currentListing, 'twitter');
        const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
        const siteTitle = config.siteTitle;
        const schemaTitle = intl.formatMessage(
            { id: 'ListingPage.schemaTitle' },
            { title, price: formattedPrice, siteTitle }
        );

        const hostLink = (
            <NamedLink
                className={css.authorNameLink}
                name="ListingPage"
                params={params}
                to={{ hash: '#host' }}>
                {authorDisplayName}
            </NamedLink>
        );

        // FETCH COURT OPTIONS
        const amenitiesOptions = findOptionsForSelectFilter(
            'amenities',
            filterConfig
        );
        const courtTypeOptions = findOptionsForSelectFilter(
            'courtType',
            filterConfig
        );
        const courtSurfaceOptions = findOptionsForSelectFilter(
            'courtSurface',
            filterConfig
        );
        const certificateOptions = findOptionsForSelectFilter(
            'certificate',
            filterConfig
        );

        // REVIEWS RATING
        // const avgRating = (reviews) => {
        //   reviews.attributes.rating
        // }

        const ratingArr = reviews.map(review => review.attributes.rating);
        const avgRating = Math.ceil(
            ratingArr.reduce((curr, next) => curr + next, 0) / ratingArr.length
        );

        const lOrigin = new LatLng(
            currentListing.attributes.geolocation.lat,
            currentListing.attributes.geolocation.lng
        );
        const lBounds = new LatLngBounds(
            new LatLng(lOrigin.lat + 0.75, lOrigin.lng + 0.95),
            new LatLng(lOrigin.lat - 0.75, lOrigin.lng - 0.95)
        );

        if (this.state.canSearchForNearByListings) {
            onSearchNearByMapListings({ lOrigin: lOrigin, lBounds: lBounds });
            this.setState({ canSearchForNearByListings: false });
        }

        return (
            <Page
                title={schemaTitle}
                scrollingDisabled={scrollingDisabled}
                author={authorDisplayName}
                contentType="website"
                description={description}
                facebookImages={facebookImages}
                twitterImages={twitterImages}
                schema={{
                    '@context': 'http://schema.org',
                    '@type': 'ItemPage',
                    description: description,
                    name: schemaTitle,
                    image: schemaImages,
                }}>
                <LayoutSingleColumn className={css.pageRoot}>
                    <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
                    <LayoutWrapperMain>
                        <div>
                            {/* NEW IMAGE LAYOUT */}
                            <SectionImages
                                title={title}
                                listing={currentListing}
                                isOwnListing={isOwnListing}
                                editParams={{
                                    id: listingId.uuid,
                                    slug: listingSlug,
                                    type: listingType,
                                    tab: listingTab,
                                }}
                                imageCarouselOpen={this.state.imageCarouselOpen}
                                onImageCarouselClose={() =>
                                    this.setState({ imageCarouselOpen: false })
                                }
                                handleViewPhotosClick={handleViewPhotosClick}
                                onManageDisableScrolling={
                                    onManageDisableScrolling
                                }
                            />

                            <div className={css.contentContainer}>
                                <div className={css.mainContent}>
                                    <div className={css.courtListingIntro}>
                                        <SectionHeading
                                            listing={currentListing}
                                            currentUser={currentUser}
                                            priceTitle={priceTitle}
                                            formattedPrice={formattedPrice}
                                            richTitle={richTitle}
                                            listingCertificate={
                                                publicData
                                                    ? publicData.certificate
                                                    : null
                                            }
                                            certificateOptions={
                                                certificateOptions
                                            }
                                            hostLink={hostLink}
                                            showContactUser={showContactUser}
                                            onContactUser={this.onContactUser}
                                            publicData={publicData}
                                            reviews={reviews}
                                            ratingArr={ratingArr}
                                            avgRating={avgRating}
                                            fetchReviewsError={
                                                fetchReviewsError
                                            }
                                            addToFav={addToFav}
                                            currentAuthor={currentAuthor}
                                        />
                                        {/* COURT_PROVIDER_AVATAR & NAME */}
                                        {currentAuthor && (
                                            <SectionAvatar
                                                user={currentAuthor}
                                                params={params}
                                            />
                                        )}
                                    </div>

                                    {/* COURT_DESC */}
                                    <SectionDescriptionMaybe
                                        description={description}
                                        publicData={publicData}
                                    />

                                    <div className={css.courtInfoContainer}>
                                        {/* COURT_TYPE */}
                                        <SectionCourtType
                                            options={courtTypeOptions}
                                            publicData={publicData}
                                        />

                                        {/* COURT_SURFACE */}
                                        <SectionCourtSurface
                                            options={courtSurfaceOptions}
                                            publicData={publicData}
                                        />
                                    </div>

                                    {/* AMMENITIES */}
                                    <SectionFeaturesMaybe
                                        options={amenitiesOptions}
                                        publicData={publicData}
                                    />

                                    {/* ADDITIONAL_INFO - players allowed, parking spaces, rules & owner notes*/}
                                    <SectionInfo publicData={publicData} />

                                    <SectionVideo
                                        description={description}
                                        publicData={publicData}
                                    />

                                    <SectionMapMaybe
                                        geolocation={geolocation}
                                        publicData={publicData}
                                        listingId={currentListing.id}
                                    />

                                    {/* CANCELLATION POLICY */}
                                    <SectionPolicy />

                                    {/* REVIEWS */}
                                    <SectionReviews
                                        reviews={reviews}
                                        fetchReviewsError={fetchReviewsError}
                                    />

                                    {/* Nearby Listings 
                                        <SectionNearbyListings
                                            currentListing={currentListing}
                                            nearbyListings={nearbyListings}
                                        />
                                    */}
                                    
                                </div>

                                <BookingPanel
                                    className={css.bookingPanel}
                                    listing={currentListing}
                                    isOwnListing={isOwnListing}
                                    unitType={unitType}
                                    onSubmit={handleBookingSubmit}
                                    onSubmitInstant={handleInstantBookingSubmit}
                                    title={bookingTitle}
                                    authorDisplayName={authorDisplayName}
                                    onManageDisableScrolling={
                                        onManageDisableScrolling
                                    }
                                    monthlyTimeSlots={monthlyTimeSlots}
                                    onFetchTimeSlots={onFetchTimeSlots}
                                    // fetch line items
                                    onFetchTransactionLineItems={
                                        onFetchTransactionLineItems
                                    }
                                    lineItems={lineItems}
                                    fetchLineItemsInProgress={
                                        fetchLineItemsInProgress
                                    }
                                    fetchLineItemsError={fetchLineItemsError}
                                    addNewBooking={addNewBooking}
                                    deleteBooking={deleteBooking}
                                    // bookingDatesArray={bookingDatesArray}
                                    bookingDatesArray={
                                        this.state.bookingsDatesArray
                                    }
                                    storeBookingDatesArray={onStoreBookingDates}
                                    // removeBookingDates={onRemoveBookingDate}
                                    removeBookingDates={deleteBooking}
                                    onContactUser={this.onContactUser}
                                />
                            </div>
                        </div>
                        <Modal
                            id="ListingPage.enquiry"
                            contentClassName={css.enquiryModalContent}
                            isOpen={
                                isAuthenticated && this.state.enquiryModalOpen
                            }
                            onClose={() =>
                                this.setState({ enquiryModalOpen: false })
                            }
                            onManageDisableScrolling={onManageDisableScrolling}>
                            <EnquiryForm
                                className={css.enquiryForm}
                                submitButtonWrapperClassName={
                                    css.enquirySubmitButtonWrapper
                                }
                                listingTitle={title}
                                authorDisplayName={authorDisplayName}
                                sendEnquiryError={sendEnquiryError}
                                onSubmit={this.onSubmitEnquiry}
                                inProgress={sendEnquiryInProgress}
                            />
                        </Modal>
                        <Modal
                            id="ListingPage.thanksModalOpen"
                            contentClassName={css.enquiryModalContent}
                            isOpen={
                                isAuthenticated &&
                                this.state.thanksModalOpen &&
                                currentListing.attributes.state ==
                                    'pendingApproval'
                            }
                            onClose={() =>
                                this.setState({ thanksModalOpen: false })
                            }
                            onManageDisableScrolling={onManageDisableScrolling}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}>
                                <img
                                    src={successImage}
                                    style={{ width: '60%', maxWidth: '300px' }}
                                />
                                <h2>Thank you for publishing your listing.</h2>
                                <span>
                                    You will be notified within 24-48 hours that
                                    your listing has been approved. Find out
                                    more about being a court owner{' '}
                                    <a
                                        href="https://rentmycourt.com/court-owner/"
                                        target="_blank">
                                        here
                                    </a>
                                </span>
                                <Button
                                    style={{ marginTop: '30px' }}
                                    onClick={() =>
                                        this.setState({
                                            thanksModalOpen: false,
                                        })
                                    }>
                                    View your court
                                </Button>
                            </div>
                        </Modal>
                        <Modal
                            id="ListingPage.fillInMissingInfo"
                            contentClassName={css.enquiryModalContent}
                            isOpen={
                                isAuthenticated &&
                                this.state.fillInInfoModalOpen &&
                                currentListing.attributes.state !==
                                    'pendingApproval'
                            }
                            onClose={() =>
                                this.setState({ fillInInfoModalOpen: false })
                            }
                            onManageDisableScrolling={onManageDisableScrolling}>
                            <div>
                                <h2>
                                    You are missing some profile information.
                                </h2>
                                <span>
                                    In order to book a court, we require all
                                    players to add a photo and complete their
                                    player profile.
                                </span>
                                <Button
                                    style={{ marginTop: '30px' }}
                                    onClick={() =>
                                        window.open(
                                            '/app/profile-settings',
                                            '_self'
                                        )
                                    }>
                                    Go to profile settings
                                </Button>
                            </div>
                        </Modal>
                    </LayoutWrapperMain>
                    <LayoutWrapperFooter>
                        <Footer />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </Page>
        );
    }
}

ListingPageComponent.defaultProps = {
    unitType: config.bookingUnitType,
    currentUser: null,
    enquiryModalOpenForListingId: null,
    showListingError: null,
    reviews: [],
    fetchReviewsError: null,
    monthlyTimeSlots: null,
    sendEnquiryError: null,
    filterConfig: config.custom.filters,
    lineItems: null,
    fetchLineItemsError: null,
    bookingDatesArray: [],
    nearbyListings: [],
};

ListingPageComponent.propTypes = {
    // from withRouter
    history: shape({
        push: func.isRequired,
    }).isRequired,
    location: shape({
        search: string,
    }).isRequired,

    onSearchNearByMapListings: func.isRequired,
    nearbyListings: array,

    unitType: propTypes.bookingUnitType,
    // from injectIntl
    intl: intlShape.isRequired,

    params: shape({
        id: string.isRequired,
        slug: string,
        variant: oneOf([
            LISTING_PAGE_DRAFT_VARIANT,
            LISTING_PAGE_PENDING_APPROVAL_VARIANT,
        ]),
    }).isRequired,

    isAuthenticated: bool.isRequired,
    currentUser: propTypes.currentUser,
    getListing: func.isRequired,
    getOwnListing: func.isRequired,
    onManageDisableScrolling: func.isRequired,
    scrollingDisabled: bool.isRequired,
    enquiryModalOpenForListingId: string,
    showListingError: propTypes.error,
    callSetInitialValues: func.isRequired,
    reviews: arrayOf(propTypes.review),
    fetchReviewsError: propTypes.error,
    monthlyTimeSlots: object,
    // monthlyTimeSlots could be something like:
    // monthlyTimeSlots: {
    //   '2019-11': {
    //     timeSlots: [],
    //     fetchTimeSlotsInProgress: false,
    //     fetchTimeSlotsError: null,
    //   }
    // }
    sendEnquiryInProgress: bool.isRequired,
    sendEnquiryError: propTypes.error,
    onSendEnquiry: func.isRequired,
    onInitializeCardPaymentData: func.isRequired,
    filterConfig: array,
    onFetchTransactionLineItems: func.isRequired,
    lineItems: array,
    fetchLineItemsInProgress: bool.isRequired,
    fetchLineItemsError: propTypes.error,
    bookingDatesArray: array,
};

const mapStateToProps = state => {
    const { isAuthenticated } = state.Auth;
    const {
        showListingError,
        reviews,
        fetchReviewsError,
        monthlyTimeSlots,
        sendEnquiryInProgress,
        sendEnquiryError,
        lineItems,
        fetchLineItemsInProgress,
        fetchLineItemsError,
        enquiryModalOpenForListingId,
        bookingsDatesArray,
        bookingDatesArray,
        nearbyListings,
    } = state.ListingPage;

    const { currentUser } = state.user;

    const getListing = id => {
        const ref = { id, type: 'listing' };
        const listings = getMarketplaceEntities(state, [ref]);
        return listings.length === 1 ? listings[0] : null;
    };

    const getOwnListing = id => {
        const ref = { id, type: 'ownListing' };
        const listings = getMarketplaceEntities(state, [ref]);
        return listings.length === 1 ? listings[0] : null;
    };

    return {
        isAuthenticated,
        currentUser,
        getListing,
        getOwnListing,
        scrollingDisabled: isScrollingDisabled(state),
        enquiryModalOpenForListingId,
        showListingError,
        reviews,
        fetchReviewsError,
        monthlyTimeSlots,
        lineItems,
        fetchLineItemsInProgress,
        fetchLineItemsError,
        sendEnquiryInProgress,
        sendEnquiryError,
        bookingDatesArray,
        nearbyListings,
    };
};

const mapDispatchToProps = dispatch => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
    callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
        dispatch(setInitialValues(values, saveToSessionStorage)),

    // request for new data for every date pick actions
    onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
        dispatch(
            fetchTransactionLineItems(bookingData, listingId, isOwnListing)
        ),

    // store every date added
    onStoreBookingDates: dateValue =>
        dispatch(storeBookingDatesArray(dateValue)),
    onRemoveBookingDate: dateIndex => dispatch(removeBookingDate(dateIndex)),

    onSendEnquiry: (listingId, message) =>
        dispatch(sendEnquiry(listingId, message)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onFetchTimeSlots: (listingId, start, end, timeZone) =>
        dispatch(fetchTimeSlots(listingId, start, end, timeZone)),

    onUpdateProfile: data => dispatch(updateProfile(data)),
    onSearchNearByMapListings: searchParams =>
        dispatch(searchNearByMapListings(searchParams)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(ListingPageComponent);

export default ListingPage;
