import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
    const { description, publicData } = props;
    return description ? (
        <div className={css.sectionDescription}>
            <h2 className={css.descriptionTitle}>
                <FormattedMessage id="ListingPage.descriptionTitle" />
            </h2>
            <h3 style={{ marginBottom: '0px' }}>Description</h3>
            <p className={css.description}>
                {richText(description, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                    longWordClass: css.longWord,
                })}
            </p>

            {publicData.courtCondition && (
                <>
                    <h3 style={{ marginBottom: '0px' }}>Condition</h3>
                    <p className={css.description}>
                        {richText(publicData.courtCondition, {
                            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                            longWordClass: css.longWord,
                        })}
                    </p>
                </>
            )}
        </div>
    ) : null;
};

export default SectionDescriptionMaybe;
