import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Map } from '../../components';
import config from '../../config';
import { IoLocationOutline } from 'react-icons/io5';

import css from './ListingPage.module.css';

class SectionMapMaybe extends Component {
    constructor(props) {
        super(props);
        this.state = { isStatic: true };
    }

    render() {
        const {
            className,
            rootClassName,
            geolocation,
            publicData,
            listingId,
        } = this.props;

        if (!geolocation) {
            return null;
        }

        const address =
            publicData && publicData.location
                ? publicData.location.address
                : '';
        const classes = classNames(rootClassName || css.sectionMap, className);
        const cacheKey = listingId
            ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}`
            : null;

        const mapProps = config.maps.fuzzy.enabled
            ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
            : { address, center: geolocation };
        const map = <Map {...mapProps} useStaticMap={this.state.isStatic} />;

        return (
            <div className={classes}>
                <h2 className={css.locationTitle}>
                    <svg
                        width="21"
                        height="25"
                        viewBox="0 0 21 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.5002 0.416687C7.95306 0.41656 5.50885 1.42173 3.69875 3.21373C1.88865 5.00572 0.858967 7.43972 0.833496 9.98669C0.833496 16.6084 9.35225 23.9792 9.71475 24.2934C9.93361 24.4806 10.2122 24.5834 10.5002 24.5834C10.7882 24.5834 11.0667 24.4806 11.2856 24.2934C11.7085 23.9792 20.1668 16.6084 20.1668 9.98669C20.1414 7.43972 19.1117 5.00572 17.3016 3.21373C15.4915 1.42173 13.0473 0.41656 10.5002 0.416687ZM10.5002 21.7438C8.48225 19.8225 3.25016 14.4938 3.25016 9.98669C3.25016 8.06387 4.014 6.2198 5.37364 4.86016C6.73328 3.50052 8.57734 2.73669 10.5002 2.73669C12.423 2.73669 14.267 3.50052 15.6267 4.86016C16.9863 6.2198 17.7502 8.06387 17.7502 9.98669C17.7502 14.4575 12.5181 19.8225 10.5002 21.7438Z"
                            fill="#059669"
                        />
                        <path
                            d="M10.5002 5.25C9.66372 5.25 8.84605 5.49804 8.15057 5.96274C7.45508 6.42745 6.91302 7.08796 6.59292 7.86074C6.27283 8.63351 6.18908 9.48386 6.35226 10.3042C6.51544 11.1246 6.91823 11.8782 7.50969 12.4696C8.10115 13.0611 8.85472 13.4639 9.6751 13.6271C10.4955 13.7903 11.3458 13.7065 12.1186 13.3864C12.8914 13.0663 13.5519 12.5242 14.0166 11.8288C14.4813 11.1333 14.7293 10.3156 14.7293 9.47917C14.7293 8.35752 14.2838 7.28182 13.4906 6.48869C12.6975 5.69557 11.6218 5.25 10.5002 5.25V5.25ZM10.5002 11.2917C10.1417 11.2917 9.79126 11.1854 9.49319 10.9862C9.19513 10.787 8.96282 10.504 8.82563 10.1728C8.68845 9.84159 8.65256 9.47716 8.72249 9.12557C8.79243 8.77397 8.96505 8.45102 9.21853 8.19754C9.47202 7.94405 9.79497 7.77143 10.1466 7.70149C10.4982 7.63156 10.8626 7.66745 11.1938 7.80463C11.525 7.94182 11.808 8.17413 12.0072 8.4722C12.2064 8.77026 12.3127 9.12069 12.3127 9.47917C12.3127 9.95987 12.1217 10.4209 11.7818 10.7608C11.4419 11.1007 10.9809 11.2917 10.5002 11.2917Z"
                            fill="#059669"
                        />
                    </svg>
                    &nbsp;
                    <FormattedMessage id="ListingPage.locationTitle" />
                </h2>
                <div>
                    {this.state.isStatic ? (
                        <button
                            className={css.map}
                            onClick={() => {
                                this.setState({ isStatic: false });
                            }}>
                            {map}
                        </button>
                    ) : (
                        <div className={css.map}>{map}</div>
                    )}
                </div>
                <small className={css.mapDetailsNote}>
                    <FormattedMessage id="ListingPage.locationNotes" />
                </small>
            </div>
        );
    }
}

SectionMapMaybe.defaultProps = {
    rootClassName: null,
    className: null,
    geolocation: null,
    listingId: null,
};

SectionMapMaybe.propTypes = {
    rootClassName: string,
    className: string,
    geolocation: propTypes.latlng,
    listingId: propTypes.uuid,
};

export default SectionMapMaybe;
