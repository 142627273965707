import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionVideo = props => {
    const { description, publicData } = props;
    return description ? (
        <div className={css.sectionDescription}>
            {publicData && publicData.youtubeLink && (
                <div className={css.youtubeContainer}>
                    <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${publicData &&
                            publicData.youtubeLink &&
                            publicData.youtubeLink.replace(
                                /https\:\/\/www.youtube.com\/watch\?v\=/g,
                                ''
                            )}`}
                        title="YouTube video player"
                        frameBorder="0"
                        // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                </div>
            )}
        </div>
    ) : null;
};

export default SectionVideo;
