import React from 'react';
import { PropertyGroup } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { ReactComponent as PlayerParking } from '../../assets/icons/player-parking-icon.svg';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionInfo = props => {
    const { description, publicData, options } = props;
    //   if (!publicData) {
    //     return null;
    //   }

    const players =
        publicData && publicData.howManyPlayersAllowed
            ? publicData.howManyPlayersAllowed
            : [];
    const parking =
        publicData && publicData.parkingSpots ? publicData.parkingSpots : [];
    const rules = publicData && publicData.rules ? publicData.rules : [];
    const notes = publicData && publicData.notes ? publicData.notes : '';
    //   const selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));

    return (
        <div className={css.sectionInfo}>
            <h2 className={css.courtInfoTitle}>
                <PlayerParking />
                &nbsp;
                <FormattedMessage id="ListingPage.playersParkingTitle" />
            </h2>
            <ul>
                <li className={css.sectionInfoList}>
                    <b className={css.infoTitle}>
                        <FormattedMessage id="ListingPage.howManyPlayersAllowed" />
                    </b>
                    &nbsp;&nbsp;<div className={css.infoText}>{players}</div>
                </li>
                <li className={css.sectionInfoList}>
                    <b className={css.infoTitle}>
                        <FormattedMessage id="ListingPage.parkingSpots" />
                    </b>
                    &nbsp;&nbsp;<div className={css.infoText}>{parking}</div>
                </li>
                <li className={css.sectionInfoListCol}>
                    <b className={css.infoTitle}>
                        <FormattedMessage id="ListingPage.ownerRules" />
                    </b>
                    <div className={css.infoText}>{rules}</div>
                </li>
                <div className={css.sectionInfoListCol}>
                    <b className={css.infoTitle}>
                        <FormattedMessage id="ListingPage.ownerNotes" />
                    </b>
                    <div className={css.infoText}>
                        {notes
                            .split('-')
                            .filter(s => s !== '')
                            .map((s, i) => (
                                <li className={css.infoText} key={i}>
                                    {s}
                                </li>
                            ))}
                    </div>
                </div>
            </ul>
        </div>
    );
};

export default SectionInfo;
