import React from 'react';
import { arrayOf, func, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';

const ResponsiveImageLayout = props => {
    const {
        className,
        rootClassName,
        alt,
        noImageMessage,
        image,
        variants,
        setSelectedImage,
        ...rest
    } = props;
    const classes = classNames(rootClassName || css.root, className);

    if (image == null || variants.length === 0) {
        const noImageClasses = classNames(
            rootClassName || css.root,
            css.noImageContainer,
            className
        );

        const noImageMessageText = noImageMessage || (
            <FormattedMessage id="ResponsiveImage.noImage" />
        );
        return (
            <div className={noImageClasses}>
                <div className={css.noImageWrapper}>
                    <NoImageIcon className={css.noImageIcon} />
                    <div className={css.noImageText}>{noImageMessageText}</div>
                </div>
            </div>
        );
    }

    const imageVariants = image.attributes.variants;
    // imageVariants fetch the array of image's variants

    const srcSet = variants
        .map(variantName => {
            const variant = imageVariants[variantName];

            if (!variant) {
                // Variant not available (most like just not loaded yet)
                return null;
            }

            //  variant.url = "https://....." variant.width = "width size property"
            return `${variant.url} ${variant.width}w`;
        })
        .filter(v => v != null)
        .join(', ');

    const imgProps = {
        className: classes,
        srcSet,
        ...rest,
    };

    return (
        <img
            alt={alt}
            {...imgProps}
            onClick={() => setSelectedImage(image) || {}}
        />
    );
};

ResponsiveImageLayout.defaultProps = {
    className: null,
    rootClassName: null,
    image: null,
    noImageMessage: null,
};

ResponsiveImageLayout.propTypes = {
    className: string,
    rootClassName: string,
    alt: string.isRequired,
    image: propTypes.image,
    variants: arrayOf(string).isRequired,
    noImageMessage: string,
    setSelectedImage: func,
};

export default ResponsiveImageLayout;
