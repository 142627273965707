import React, { useState, useEffect } from 'react';
import { PropertyGroup } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { ReactComponent as IconCourtType } from '../../assets/icons/courtType.svg';

import css from './ListingPage.module.css';

const SectionCourtType = props => {
    const { publicData, options } = props;
    
    const [allOptions, setAllOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        const { typeOutdoor, typeIndoor, courtType } = publicData;
        let selectedOptions = [];
        if (typeIndoor && typeOutdoor) {
            selectedOptions = [
                ...new Set([...typeIndoor, ...typeOutdoor, ...courtType]),
            ];
        } else if (courtType) {
            selectedOptions = courtType;
        }
        var allOptions = options
            .filter(o => selectedOptions.includes(o.key))
            .map(o => {
                let showLabel;
                var indoor = typeIndoor?.includes(o.key);
                var outdoor = typeOutdoor?.includes(o.key);
                if (indoor && outdoor) {
                    showLabel = 'INDOOR & OUTDOOR';
                } else if (indoor) {
                    showLabel = 'INDOOR';
                } else if (outdoor) {
                    showLabel = 'OUTDOOR';
                }
                return { ...o, showLabel: showLabel ? label(showLabel) : '' };
            });
        setAllOptions(allOptions);
        setSelectedOptions(selectedOptions);
    }, [publicData]);

    const label = showLabel => {
        return <div className={css.labelContainer}>{showLabel}</div>;
    };

        
    if (!publicData) {
        return null;
    }

    return (
        <div className={css.sectionCourtInfo}>
            <>
                <h2 className={css.courtInfoTitle}>
                    <IconCourtType />
                    &nbsp;
                    <FormattedMessage id="ListingPage.courtType" />
                </h2>
            </>

            <PropertyGroup
                id="ListingPage.courtType"
                options={allOptions}
                selectedOptions={selectedOptions}
                // twoColumns={selectedConfigOptionsIndoor.length > 2}
            />
        </div>
    );
};

export default SectionCourtType;
