import React from 'react';
import { PropertyGroup } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { BsBoundingBoxCircles } from 'react-icons/bs';
import { ReactComponent as IconCourtSurface } from '../../assets/icons/geo_turf-point-grid.svg';

import css from './ListingPage.module.css';

const SectionCourtSurface = props => {
    const { description, publicData, options } = props;
    if (!publicData) {
        return null;
    }

    const selectedOptions =
        publicData && publicData.courtSurface ? publicData.courtSurface : [];
    const selectedConfigOptions = options.filter(o =>
        selectedOptions.find(s => s === o.key)
    );

    return (
        <div className={css.sectionCourtInfo}>
            <h2 className={css.courtInfoTitle}>
                <IconCourtSurface />
                &nbsp;
                <FormattedMessage id="ListingPage.courtSurface" />
            </h2>
            <PropertyGroup
                id="ListingPage.courtSurface"
                options={selectedConfigOptions}
                selectedOptions={selectedOptions}
                twoColumns={selectedConfigOptions.length > 2}
            />
        </div>
    );
};

export default SectionCourtSurface;
