import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, ReviewRating } from '../../components';
import { ensureListing } from '../../util/data';

import css from './ListingPage.module.css';
import SectionReviews from './SectionReviews';
import CourtRating from '../../components/CourtRating/CourtRating';
import heartIcon from '../../assets/icons/817c46f0490bf236f3c30f7fdd6e5df3 copy.svg';
import heartIconFilled from '../../assets/icons/817c46f0490bf236f3c30f7fdd6e5df3.svg';

const getCertificateInfo = (certificateOptions, key) => {
    return certificateOptions.find(c => c.key === key);
};

const SectionHeading = props => {
    const {
        listing,
        richTitle,
        listingCertificate,
        certificateOptions,
        showContactUser,
        onContactUser,
        publicData,
        reviews,
        ratingArr,
        avgRating,
        fetchReviewsError,
        currentUser,
        addToFav,
        currentAuthor,
    } = props;

    const currentListing = ensureListing(listing);
    const id = currentListing.id.uuid;
    let city = '-';

    if (publicData.location && publicData.location.city) {
        city = publicData.location.city;
    } else if (
        currentAuthor &&
        currentAuthor.attributes &&
        currentAuthor.attributes.profile &&
        currentAuthor.attributes.profile.publicData &&
        currentAuthor.attributes.profile.publicData.city
    ) {
        city = currentAuthor.attributes.profile.publicData.city;
    }

    let state = '-';

    if (publicData.location && publicData.location.state) {
        state = publicData.location.state;
    } else if (
        currentAuthor &&
        currentAuthor.attributes &&
        currentAuthor.attributes.profile &&
        currentAuthor.attributes.profile.publicData &&
        currentAuthor.attributes.profile.publicData.state
    ) {
        state = currentAuthor.attributes.profile.publicData.state;
    }

    const ratingScore =
        publicData && publicData.ratingScore ? publicData.ratingScore : null;
    const listingRating = ratingScore / 10; //sharetribe only can store round numbers, ratingScore is store as round number (times 10)

    const currentUserProfile =
        currentUser && currentUser.attributes && currentUser.attributes.profile;
    const { favCourts } =
        (currentUserProfile && currentUserProfile.publicData) || {};
    const isFavCourt =
        favCourts && favCourts.length ? favCourts.includes(id) : false;

    return (
        <div className={css.sectionHeading}>
            <div className={css.heading}>
                <h1 className={css.title}>{richTitle}</h1>

                {/* CITY & STATE SECTION*/}
                <div className={css.address}>
                    <span>
                        <span className={css.lightFont}>City</span>&nbsp;&nbsp;
                        <strong>{city}</strong>
                    </span>{' '}
                    &emsp;&nbsp;
                    <span>
                        <span className={css.lightFont}>State</span>&nbsp;&nbsp;
                        <strong>{state}</strong>
                    </span>
                </div>
                <div>
                    <small>
                        <div className={css.ratingfav}>
                            <div className={css.ratingContainer}>
                                <CourtRating avgRating={listingRating} />
                                &nbsp;&nbsp;
                                <FormattedMessage
                                    id="ListingPage.reviewsStat"
                                    values={{ count: reviews.length }}
                                />
                            </div>
                            <div className={css.favContainer}>
                                <div className={css.favIcon}>
                                    <img
                                        src={
                                            isFavCourt
                                                ? heartIconFilled
                                                : heartIcon
                                        }
                                        onMouseOver={e =>
                                            (e.currentTarget.src = heartIconFilled)
                                        }
                                        onMouseOut={e =>
                                            (e.currentTarget.src = isFavCourt
                                                ? heartIconFilled
                                                : heartIcon)
                                        }
                                        onClick={() => addToFav(id)}
                                        className={css.favIconHover}
                                    />
                                </div>
                                &nbsp;&nbsp;
                                <FormattedMessage id="ListingPage.addToFav" />
                            </div>
                        </div>
                    </small>
                </div>

                {/* <div className={css.author}>
          {showCertificate ? <span>{certificate.label}</span> : null}
          {showContactUser ? (
            <span className={css.contactWrapper}>
              {showCertificate ? <span className={css.separator}>•</span> : null}
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div> */}
            </div>
        </div>
    );
};

export default SectionHeading;
